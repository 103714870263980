(function(app) {
	const selectors = {
		swatches: '.js-swatchanchor',
		pdpSwatches: '.js-swatchanchor-pdp',
		pdpSwatchesSelected: '.js-swatchanchor-pdp-selected',
		main: '.js-pdp_main'
	};

	const templates = {
		flyout: 'widthFlyoutTemplate'
	};

	const settings = {
		scroll: {
			speed: 500,
			animate: 'swing'
		}
	};

	function WidthFlyout(config) {
		app.flyouts.Flyout.call(this, 'width', config);
	}

	WidthFlyout.prototype = Object.create(app.flyouts.Flyout.prototype);
	WidthFlyout.prototype.constructor = app.flyouts.Flyout;

	WidthFlyout.prototype.open = function(params) {
		this.markup = getMarkup();

		app.flyouts.Flyout.prototype.open.call(this, params);
	};

	WidthFlyout.prototype.close = function(keepFocus) {
		app.flyouts.Flyout.prototype.close.call(this, keepFocus);
	};

	WidthFlyout.prototype.initMarkup = function(params) {
		addSwatchClickListeners.call(this, params);
		addPDPSwatchClickListeners.call(this, params);

		app.flyouts.Flyout.prototype.initMarkup.call(this, params);
	};

	/**
	 * Adds click event listeners to the swatches on the page. Closes the flyout when a swatch is clicked.
	 * @param {Object} params - configuration parameters for the flyout
	 */
	function addSwatchClickListeners(params) {
		const widthSwatchNodes = document.querySelectorAll(selectors.swatches);

		widthSwatchNodes.forEach((widthSwatchNode) => {
			widthSwatchNode.addEventListener('click', () => {
				this.close(params);
			});
		});
	}

	/**
	 * Adds click event listeners to the swatches on the PDP. Triggers the handling of PDP swatch clicks.
	 * @param {Object} params - configuration parameters for the flyout
	 */
	function addPDPSwatchClickListeners(params) {
		const widthPDPSwatchNodes = document.querySelectorAll(selectors.pdpSwatches);

		widthPDPSwatchNodes.forEach((widthPDPSwatchNode) => {
			widthPDPSwatchNode.addEventListener('click', (e) => {
				handlePDPSwatchClick.call(this, e, params);
			});
		});
	}

	/**
	 * Handles the click event on PDP swatches. Updates the main content and scrolls to the top of the page.
	 * Updates the URL with the new product's href.
	 * @param {Event} e - the event object representing the click event
	 * @param {Object} params - configuration parameters for the flyout
	 */
	function handlePDPSwatchClick(e, params) {
		if (e.target.closest(selectors.pdpSwatchesSelected)) {
			this.close(params);
		} else {
			e.delegateTarget = document.querySelector(selectors.main);
			app.product.changeMainContent(e);
			this.close(params);
			$('html, body').animate({ scrollTop: 0 }, settings.scroll.speed, settings.scroll.animate);
			const href = e.target.dataset.href;

			if (href) {
				window.history.pushState(null, '', href);
			}
		}
	}

	function getMarkup() {
		const template = document.getElementById(templates.flyout);

		return app.util.renderTemplate(template.innerHTML);
	}

	app.flyouts.width = new WidthFlyout(app.configs.flyoutComponent);
})((window.app = window.app || {}), jQuery);
